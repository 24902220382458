<template>
	<div>
		
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()" />
				</div>
			</div>
		</template>
		
		<b-card no-body class="mb-0">

		<b-tabs pills justified class="margin_top_zero_mobile">               
            
			<b-tab title="Send Broadcast Message" :active="tab_status == 'send_broadcast_message'" @click="resetData()">
				<b-card title="" class="mb-0">
					<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1" >
						<div class="alert-body">
							{{error_message}}
						</div>
					</b-alert>
			
					<b-form @submit="formSubmit">
						<b-row>
							<b-col md="12">
								<b-form-group label="Notification Type" class="required mb-1" >
									<div class="demo-inline-spacing">
										<b-form-radio class="mb-0 mt-0 pt-0" key="push" value="push_notification" v-model="form.notification_type" @change="form.description = ''">
											Push Notification
										</b-form-radio>
										<b-form-radio class="mb-0 mt-0 pt-0" key="email" value="email_notification" v-model="form.notification_type" @change="form.description = ''">
											Email Notification
										</b-form-radio>
									</div>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row>
							<b-col md="12">
								<b-form-group label="Title" class="required">
									<b-form-input placeholder="3 to 50 characters" autocomplete="off" v-model="form.title"/>
								</b-form-group>
							</b-col>
						</b-row>
						
						<b-row>
							<b-col md="12">
								<b-form-group label="Selected User" class="required mb-0" >
									<div class="demo-inline-spacing">
										<b-form-radio class="mb-0 mt-0 pt-0" key="all_users" value="all_users" v-model="form.selected_user" @input="company_list = []; user_list = []; form.companies = []; form.roles = []; form.users = [];">
											All Users
										</b-form-radio>
										<b-form-radio class="mb-0 mt-0 pt-0" key="selective_users" value="selective_users" v-model="form.selected_user" @input="getCompanies();">
											Selective Users
										</b-form-radio>
									</div>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row class="mt-1" v-if="form.selected_user == 'selective_users'">
							<b-col md="4">
								<b-form-group label="Client" class="required">
									<v-select
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="company_list"
										label="organization_name"
										class="w-100"
										placeholder="Select Client"
										@input="getUsers(); form.roles=[]; form.users=[]"
										v-model="form.companies"
										multiple
									/>
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group label="Role" class="required">
									<v-select
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="role_list"
										class="w-100"
										placeholder="Select Role"
										@input="getUsers(); form.users=[]"
										v-model="form.roles"
										multiple
									>
										<template slot="option" slot-scope="option">
											<span></span>
												{{ option.label | capitalize }}
										</template>
										
										<template slot="selected-option" slot-scope="option">
											<span></span>
												{{ option.label | capitalize }}
										</template>
									</v-select>
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group label="Users" class="required">
									<v-select
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="user_list"
										label="full_name"
										class="w-100"
										placeholder="Select User"
										v-model="form.users"
										multiple
									/>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row class="mt-1">
							<b-col md="12">
								<b-form-group label="Description" class="required">
									<b-form-textarea v-if="form.notification_type == 'push_notification'" placeholder="" rows="3" v-model="form.description"/>
									<ckeditor v-else v-model="form.description" :config="editorConfig"></ckeditor>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row>
							<b-col>
								<b-button type="submit" variant="warning" class="mr-1"> Submit </b-button>
								<!-- <b-button variant="outline-secondary" @click="$router.push({ name: 'client-list'})"> Cancel </b-button> -->
							</b-col>
						</b-row>

					</b-form>
				</b-card>
			</b-tab>

			<b-tab title="All Broadcast Message" :active="tab_status == 'all_broadcast_message'">
				<b-card no-body class="mb-0" >

					<div class="m-2">
						<b-row>
							<!-- Per Page -->
							<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" >
								<label>Show</label>
									<v-select
										v-model="perPage"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="perPageOptions"
										:clearable="false"
										class="per-page-selector d-inline-block mx-50"
										@input="filterTable"
									/>
								<label>entries</label>
							</b-col>
							<!-- Search -->
							<b-col cols="12" md="6" >
								<div class="d-flex align-items-center justify-content-end mobile-view">
									<b-form-input
										v-model="searchQuery"
										class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
										placeholder="Search..."
										@input="filterTable"
									/>
									<b-button
										:disabled="selected.length > 0 ? false : true"
										variant="danger"
										class="mobile-margin-bottom"
										@click="deleteMany('deleted')"
									>
										<span class="text-nowrap">Delete</span>
									</b-button>
								</div>
							</b-col>
						</b-row>
					</div>

					<b-table sticky-header
						ref="refUserListTable"
						class="position-relative bigTable common_big_tbl"
						:items="dataList"
						responsive
						:fields="tableColumns"
						primary-key="id"
						:sort-by.sync="sortBy"
						show-empty
						empty-text="No matching records found"
						:sort-desc.sync="isSortDirDesc"
						:class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
					>

						<template #head(checkbox)="items">
							<b-form-checkbox class="custom-control-primary" v-model="selectAll" @change="selectall"/>
						</template>

						<template #cell(checkbox)="items">
							<b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-primary my-1" />
						</template>

						<template #cell(title)="items">
							<span @click="items.toggleDetails">{{items.item.title}}</span>
						</template>

						 <template #cell(info)="items">             
							<feather-icon
							:icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
							@click="items.toggleDetails"
							/>
						</template>
						<template #cell(description)="items">
							<span v-if="items.item.notification_type == 'push_notification'" @click="items.toggleDetails">
								<span class="wordBreak" v-if="items.item.description.length > 200" v-b-tooltip.hover.v-warning :title="items.item.description">{{items.item.description | truncate200}}</span>
								<span class="wordBreak" v-else>{{items.item.description}}</span>
							</span>
							<div v-else style="max-height: 120px !important; overflow: auto;">
							<!-- <div v-else> -->
								<span class="wordBreak" v-html="items.item.description" @click="items.toggleDetails"></span>
								<!-- <span>{{ sanitizeHtml(items.item.description) }}</span> -->
							</div>
						</template>

						<template #cell(organization)="items">
							<div v-if="items.item.organization.length > 0">
								<span v-if="items.item.organization.length > 3" v-b-tooltip.hover.v-warning :title="getOrganization(items.item.organization).toString()" @click="items.toggleDetails">
									{{ getOrganization(items.item.organization.slice(0, 3)).toString() }}
									<b-badge pill variant="primary"> +{{ (items.item.organization.length) - 3 }} </b-badge>
								</span>
								<span v-else @click="items.toggleDetails">{{ getOrganization(items.item.organization).toString() }}</span>
							</div>
							<span v-else @click="items.toggleDetails">All</span>
						</template>
						
						<template #cell(users)="items">
							<div v-if="items.item.users.length > 0">
								<span v-if="items.item.users.length > 3" v-b-tooltip.hover.v-warning :title="getUser(items.item.users).toString()" @click="items.toggleDetails">
									{{ getUser(items.item.users.slice(0, 3)).toString() }}
									<b-badge pill variant="primary"> +{{ (items.item.users.length) - 3 }} </b-badge>
								</span>
								<span v-else @click="items.toggleDetails">{{ getUser(items.item.users).toString() }}</span>
							</div>
							<span v-else @click="items.toggleDetails">All</span>
						</template>

						<!-- <template #cell(notification_type)="items">
							<span>{{ items.item.notification_type | removeUnderCapitalize }}</span>
						</template> -->
						
						<!-- <template #cell(created_at)="items">
							<span>{{ items.item.created_at | dateTime }}</span>
						</template> -->

						<template #cell(status)="items">
							<b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize" @click="items.toggleDetails">
								{{ items.item.status}}
							</b-badge>
						</template>

						<template #cell(actions)="items">
							<b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item._id)">
								<feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
							</b-link>
							<b-link @click="resendMessage(items.item)" v-b-tooltip.hover.v-warning title="Resend" variant="outline-warning">
								<feather-icon icon="RefreshCwIcon" class="mediumSize ml-1" />
							</b-link>
						</template>

						<template #row-details="items">
						<div class="p-1 px-2 fbDetail mb-1">
							<b-row>

							<b-col cols="12" xl="12" >
								<table class="mt-2 mt-xl-0 w-100">
									<tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												Type
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
											<span>{{ items.item.notification_type | removeUnderCapitalize }}</span>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												Sent On
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
											<span>{{ items.item.created_at | dateTime }}</span>
										</td>

										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												Users
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
											<div v-if="items.item.users.length > 0">
												<span v-if="items.item.users.length > 3" v-b-tooltip.hover.v-warning :title="getUser(items.item.users).toString()">
													{{ getUser(items.item.users.slice(0, 3)).toString() }}
													<b-badge pill variant="primary"> +{{ (items.item.users.length) - 3 }} </b-badge>
												</span>
												<span v-else>{{ getUser(items.item.users).toString() }}</span>
											</div>
											<span v-else>All</span>
										</td>
								
									</tr>
						
									<tr class="mb-1">
										<th class="pb-50" style="width: 16%">
										<b-button
											v-ripple.400="'rgba(113, 102, 240, 0.15)'"
											variant="outline-secondary"
											@click="items.toggleDetails"
											size="sm"
											class="ml-1"
											>
											Hide Detail
											</b-button>
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
									</tr>
								</table>
							</b-col>

							</b-row>
						</div>

						</template>
					</b-table>

					<div class="mx-2 mb-2">
						<b-row>

							<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
								<span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
							</b-col>

							<!-- Pagination -->
							<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >
								<b-pagination
									v-model="currentPage"
									:total-rows="totalRecords"
									:per-page="perPage"
									first-number
									last-number
									class="mb-0 mt-1 mt-sm-0"
									prev-class="prev-item"
									next-class="next-item"
									@input="filterTable"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</b-col>

						</b-row>
					</div>

				</b-card>
			</b-tab>
		</b-tabs>

		</b-card>
	</div>
</template>

<script>
	import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb, 
		BFormGroup, BFormCheckbox, BForm, BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, 
		BFormRadio, BInputGroupAppend, BFormTextarea, BTabs, BTab, VBTooltip
	} from 'bootstrap-vue';
	import vSelect from 'vue-select';
	import store from '@/store';
	import { ref, onUnmounted } from '@vue/composition-api';
	import { POST_API } from "../../../store/actions.type";
	import CKEditor from 'ckeditor4-vue';
	// import Ripple from 'vue-ripple-directive';
	import Bus from "../../../event-bus";

	export default {
		components: {
			BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb,
			BAlert, BFormGroup, BFormCheckbox, BForm, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup,
			BFormRadio, BInputGroupAppend, BFormTextarea, BTabs, BTab, vSelect
		},
		
		// directives: { Ripple },
		directives: { 'b-tooltip': VBTooltip },

		data() {
			return {
				tableColumns: [
					{ key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'5px'}},
					{ key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
					{ key: 'title', label: 'Title', sortable: true , thStyle:  {width: '12%'}},
					{ key: 'description', label: 'Description', sortable: true , thStyle:  {width: '22%'}},
					// { key: 'notification_type', label: 'Type', sortable: true , thStyle:  {width: '8%'}},
					{ key: 'organization', label: 'Client Name', sortable: true , thStyle:  {width: '13%'}},
					// { key: 'users', label: 'Users', sortable: true , thStyle:  {width: '15%'}},
					// { key: 'created_at', label: 'Sent On', sortable: true , thStyle:  {width: '10%'}},
					{ key: 'status', label: 'Status', sortable: true , thStyle:  {width: '7%'}},
					{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '12%'}},
				],
				
				items          : [],
				isSortDirDesc  : true,
				perPageOptions : [10, 20, 50, 100],
				perPage        : 10,
				sortBy         : 'created_at',
				webUrl         : process.env.VUE_APP_SERVER_URL,
				totalRecords   : 0,
				currentPage    : 1,
				searchQuery    : '',
				from           : null,
				to             : null,

				error_message:null,
      			showDismissibleAlert:false,

				selectAll:false,
				selected:[],

				company_list : [],
				role_list    : ['admin','administrator','operation_manager','supervisor','site_client','site_manager','crew'],
				user_list    : [],

				form:{
					notification_type : 'push_notification',
					title             : '',
					selected_user     : 'all_users',	
					companies         : [],
					roles             : [],
					users             : [],
					description       : '',
				},

				tab_status: '',

				editorConfig: {
					removeButtons:'Maximize',
					extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify',
					height:'150px'
				},
				coreStyles_bold: {
					element: 'b',
					overrides: 'strong'
				},
				// Custom style definition for the Italic feature.
				coreStyles_italic: {
					element: 'i',
					overrides: 'em'
				}
			}
		},
		mounted(){
			this.tab_status = 'send_broadcast_message';
			Bus.$on('update-tab', (data) => {
				this.tab_status = data;
			})
		},

		methods : {
			deleteMany(){
				if (this.selected.length > 0) {
					var msg = 'Are you sure you want to delete selected record?';

					this.decisionAlert(msg)
					.then(result => {
						if (result.value) {
							return this.$store.dispatch(POST_API, {
							data:{
									id: this.selected
								},
							api:"/api/broadcast-message-delete-multiple",
								})
								.then(() => {
									if (this.$store.getters.containsErrors) {
										this.error_message = this.$store.getters.getErrors;
										this.showAlert();
									} else {
										this.successAlert()
										
										this.selected = [];
										this.selectAll = false;
								
										this.$refs.refUserListTable.refresh();
									}
								});
						}
					})
					.catch(err => { })
				} else {
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Please select message(s).',
						showConfirmButton: false,
						timer: 1500
					})
				}
			},

			selectall(e){
				this.selected = [];
				if (e) {
					var selected = [];

					this.items.forEach(function(item){
						selected.push(item._id);
					})
					this.selected = selected;
				}
			},
			
			sanitizeHtml(html){
				var parser = new DOMParser();
				var doc = parser.parseFromString(html, 'text/html');
				// Extract the desired string from the HTML
				var string = doc.body.textContent;
				// Print the extracted string
				return string;
			},
			getOrganization(arr){
				return arr.map(el => ' ' + el.organization_name)
			},
			getUser(arr){
				return arr.map(el => ' ' + el.full_name)
			},
			formSubmit(e){
				e.preventDefault();

				return this.$store.dispatch(POST_API, {
					data:{
						items:this.form,
					},
					api: '/api/broadcast-message'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						
						// this.resetData();
						this.filterTable();
						this.successAlert().then(() => {
							// this.tab_status = 'all_broadcast_message';
							Bus.$emit('update-tab', 'all_broadcast_message');
						});
						
					}
				});
			
			},
		
			resendMessage(items){
				console.log(items)
				return this.$store.dispatch(POST_API, {
					data:{
						items:this.form,
						type:'resend',
					},
					api: '/api/broadcast-message'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						
						// this.resetData();
						this.filterTable();
						this.successAlert().then(() => {
							// this.tab_status = 'all_broadcast_message';
							Bus.$emit('update-tab', 'all_broadcast_message');
						});
						
					}
				});
			
			},

			resetData(){
				this.form = {
					notification_type : 'push_notification',
					title             : '',
					selected_user     : 'all_users',	
					companies         : [],
					roles             : [],
					users             : [],
					description       : '',
				};
				this.tab_status = 'send_broadcast_message';
			},
				
			dataList(){
				return this.$store.dispatch(POST_API, {
					data:{
						page          : this.currentPage,
						keyword       : this.searchQuery,
						rowsPerPage   : this.perPage,
						sortBy        : this.sortBy,
						sortDirection : this.isSortDirDesc,
					},
					api: '/api/broadcast-message-list'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.items        = this.$store.getters.getResults.data.docs;
						this.totalRecords = this.$store.getters.getResults.data.total;
						this.perPage      = this.$store.getters.getResults.data.limit;
						this.from         = this.$store.getters.getResults.data.from
						this.to           = this.$store.getters.getResults.data.to
						return this.items;
					}
				});
			},

			filterTable(){
				this.$refs.refUserListTable.refresh();
				this.selectAll = false;
      			this.selected = [];
			},

			getCompanies(){
				return this.$store.dispatch(POST_API, {
					data:{},
					api: '/api/all-client-list'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.company_list = this.$store.getters.getResults.data;

						this.form.companies = []; 
						this.form.roles     = []; 
						this.user_list      = [];
						this.form.users     = [];
					}
				});
			},

			getUsers(){
				if(this.form.companies.length > 0 && this.form.roles.length > 0){
					return this.$store.dispatch(POST_API, {
						data:{
							organization : this.form.companies,
							role         : this.form.roles
						},
						api: '/api/all-crew-by-organization-role'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
						} else {
							this.user_list = this.$store.getters.getResults.data;
						}
					});
				} else {
					this.user_list = []
				}
			},

			variantColor(status){
				if (status == 'active') {
					return 'success';
				} else {
					return 'primary';
				}
			},

			deleteItem(id){
				var msg = 'Are you sure want to delete this record?';
				var message='Record Deleted Successfully.';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: id
							},
							api:"/api/broadcast-message-delete",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								this.successAlert();
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				})
				.catch(err => { })
			},

			breadCrumb(){
				var item = [{
					to:{name:'admin-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Broadcast Message',
					active:true
				}];
				return item;
			},
		}
	
	}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';

	.nav-pills {
	     padding: 1.5rem 1.5rem 0px 1.5rem !important; 
	}
</style>
